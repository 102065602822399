import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { RelationshipFieldMany } from "../plugins/Cruxtify/field-types/RelationshipFieldMany";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  NumberProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class Language extends CrudModel {
  protected static _typeLabel = "Language";
  protected static asProperty = "language";
  public static api = {
    path: "languages",
    fullCache: true,
  };

  protected userIdField = [];
  protected static routeBase = "languages";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },

    { type: StringProperty, opts: { name: "name", label: "Name" } },
    {
      type: StringProperty,
      opts: { name: "native_name", label: "Native Name" },
    },

    // relationships
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "public_class",
        label: "Public Classes",
        relatedModel: "PublicClass",
        foreignProperty: "language",
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    { type: StringField, opts: { property: "name", isPrimaryLabelField: 1 } },
    { type: StringField, opts: { property: "native_name" } },

    // relationships
    {
      type: RelationshipFieldMany,
      opts: {
        property: "public_class",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: false,
      columnFields: ["name", "native_name"],
      filterFields: [],
      useModal: true,
      collectionActionComponents: ["collection-export"],
      searchLabel: "Search",
      defaultSort: [
        // {
        //   field: "series",
        //   order: "asc"
        // },
        // {
        //   field: "step_num",
        //   order: "asc"
        // }
      ],
    },
  ];
}
