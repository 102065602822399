import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import {
  RelationshipField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class CourseCost extends CrudModel {
  protected static _typeLabel = "Course Cost";
  protected static asProperty = "course_cost";

  public static api = {
    path: "course-costs",
    fullCache: true,
  };

  protected userIdField = [];
  protected static routeBase = "course-costs";

  protected labelGlue: string = " ";

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: StringProperty,
      opts: { name: "cost", label: "Cost" },
    },
    {
      type: StringProperty,
      opts: { name: "countries", label: "Countries" },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "course",
        label: "Course",
        relatedModel: "Course",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: StringField,
      opts: { property: "cost" },
    },

    {
      type: StringField,
      opts: { property: "countries", isPrimaryLabelField: 1 },
    },

    {
      type: RelationshipField,
      opts: { property: "course" },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      modalOptions: {
        readmodeDefault: false,
      },
      useModal: true,
      // columnFields: [],
      // filterFields: [],
    },
  ];
}
