import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { RelationshipFieldMany } from "../plugins/Cruxtify/field-types/RelationshipFieldMany";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  NumberProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import { GyroMethodField } from "../fields/GyroMethodField";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";
import { extendWithDeleted } from "./collection-layouts/extend-with-deleted";

export class Qualification extends CrudModel {
  protected static _typeLabel = "Qualification";
  protected static asProperty = "qualification";
  public static api = {
    path: "qualifications",
    fullCache: true,
  };

  protected userIdField = [];
  protected static routeBase = "qualifications";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: { name: "name", label: "Name", rules: ["required"] },
    },
    {
      type: StringProperty,
      opts: { name: "method_type", label: "Method Type" },
    },

    // relationships

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "courses",
        label: "Courses Requiring this Qualification to Teach",
        relatedModel: "Course",
        foreignProperty: "qualifications",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "resources",
        label: "Resources Requiring this Qualification to Access",
        relatedModel: "Resource",
        foreignProperty: "required_qualifications",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "trainers",
        label: "Trainers with this Qualification",
        relatedModel: "TrainerQualification",
        foreignProperty: "qualification",
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    {
      type: StringField,
      opts: { property: "name", isPrimaryLabelField: 1 },
    },
    {
      type: GyroMethodField,
      opts: { property: "method_type" },
    },

    {
      type: RelationshipFieldMany,
      opts: { property: "courses" },
    },
  ];

  protected static collectionLayoutDefinitions = extendWithDeleted([
    {
      id: "table",
      inlineEdit: false,
      useModal: true,
      columnFields: ["name", "method_type"],
      filterFields: ["method_type"],
      collectionActionComponents: ["collection-export"],
      searchLabel: "Search",
      defaultSort: [
        {
          field: "name",
          order: "asc",
        },
      ],
    },
  ]);
}
