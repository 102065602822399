import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { BooleanField } from "../plugins/Cruxtify/field-types/BooleanField";
import { EmailField } from "../plugins/Cruxtify/field-types/EmailField";
import { RelationshipFieldMany } from "../plugins/Cruxtify/field-types/RelationshipFieldMany";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  BooleanProperty,
  DateTimeProperty,
  EmailProperty,
  NumberProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import { DateTimeField, NumberField } from "../plugins/Cruxtify/field-types";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";
import { extendWithDeleted } from "./collection-layouts/extend-with-deleted";

export class User extends CrudModel {
  protected static _typeLabel = "User";
  protected static asProperty = "user";
  public static api = {
    path: "users",
  };

  protected userIdField = ["id"];
  protected static routeBase = "users";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: DateTimeProperty,
      opts: {
        name: "last_active_at",
        label: "Last Active",
        displayRelative: true,
        userPermissions: UserPermission.Read,
        isRemotelyComputed: true,
        sortable: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "last_login_at",
        label: "Last login",
        displayRelative: true,
        userPermissions: UserPermission.Read,
        isRemotelyComputed: true,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "older_legacy_id",
        label: "Older Legacy ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_admin",
        label: "Admin Account",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_super_user",
        label: "Distributor Account",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "name",
        label: "Name",
        sortable: true,
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "password",
        label: "New Password",
      },
    },
    {
      type: EmailProperty,
      opts: {
        name: "email",
        label: "Email",
        rules: ["required"],
      },
    },

    // relationships
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "trainers",
        label: "Manages Trainer Profiles",
        relatedModel: "Trainer",
        foreignProperty: "user",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "studios",
        label: "Manages Studios",
        relatedModel: "Studio",
        foreignProperty: "users",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "tradename_studios",
        label: "Tradename Owner for Studios",
        relatedModel: "Studio",
        foreignProperty: "tradename_owner",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "events",
        label: "Events",
        relatedModel: "Event",
        foreignProperty: "user",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "resources",
        label: "Resources",
        relatedModel: "Resource",
        foreignProperty: "users",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    {
      type: DateTimeField,
      opts: {
        property: "last_active_at",
      },
    },
    {
      type: BooleanField,
      opts: {
        property: "is_admin",
      },
    },
    {
      type: BooleanField,
      opts: {
        property: "is_super_user",
      },
    },
    {
      type: StringField,
      opts: {
        property: "name",
        isPrimaryLabelField: true,
        cols: 12,
      },
    },
    {
      type: EmailField,
      opts: {
        property: "email",
        cols: 6,
      },
    },
    {
      type: StringField,
      opts: {
        property: "password",
        label: "New Password (leave blank to ignore)",
      },
    },
    { type: RelationshipFieldMany, opts: { property: "trainers" } },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "studios",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "tradename_studios",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "resources",
      },
    },
    {
      type: NumberField,
      opts: {
        id: "switch-user",
        property: "id",
        label: "Impersonate",
        viewComponentName: "switch-user",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Read,
          },
          UserPermission.Hidden
        ),
      },
    },
  ];

  protected static collectionLayoutDefinitions = extendWithDeleted([
    {
      id: "table",
      columnFields: [
        "name",
        "email",
        "is_admin",
        "is_super_user",
        "created_at",
        "last_active_at",
        "switch-user",
      ],
      // filterFields: ["is_admin", "is_super_user", "email", "name"],
      useModal: true,
      enableSearch: true,
      searchLabel: "Search by Name or Email",
      enableDynamicFilters: true,
      dynamicFilterOptions: {
        filterFields: [
          "created_at",
          "updated_at",
          "last_active_at",
          "legacy_id",
          "older_legacy_id",
          "is_admin",
          "is_super_user",
          "name",
          "password",
          "email",
          "trainers",
          "studios",
          "studios.state",
          "studios.is_qualified",
          "tradename_studios",
          "events",
          "resources",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ]);

  protected static modelLayoutDefinitions = [
    {
      type: "TabbedModelLayout",
      opts: {
        id: "modellayout",
        tabs: [
          {
            id: "overviewTab",
            label: "Overview",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "name",
                    fields: ["name", "email", "password"],
                  },
                  {
                    id: "name",
                    fields: ["is_admin", "is_super_user"],
                  },
                  {
                    id: "name",
                    fields: ["id", "last_active_at"],
                  },
                ],
              },
            },
          },
          {
            id: "overviewTab",
            label: "Manages",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "name",
                    fields: ["trainers", "studios", "tradename_studios"],
                  },
                ],
              },
            },
          },
          {
            id: "overviewTab",
            label: "Resource Access",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "resources",
                    fields: ["resources"],
                  },
                ],
              },
            },
          },
        ],
      },
    },
  ];

  public switchUser() {
    return this.$nuxt.$api.POST("switch-user/" + this.id);
  }

  public static endSwitchUser() {
    return this.$nuxt.$api.POST("end-switch-user");
  }
}
