import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateProperty,
  RelationshipProperty,
} from "../plugins/Cruxtify/property-types";

import { DateField, RelationshipField } from "../plugins/Cruxtify/field-types";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";
import { Trainer } from "./Trainer";

export class TrainerQualification extends CrudModel {
  protected static _typeLabel = "Trainer Qualification";
  protected static asProperty = "trainer_qualification";
  public static api = {
    path: "trainer-qualifications",
  };

  protected userIdField = [];
  protected static routeBase = "trainer-qualifications";

  protected labelGlue: string = " ";

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: DateProperty,
      opts: { name: "certified_at", label: "Date" },
    },

    // relationships

    {
      type: RelationshipProperty,
      opts: {
        name: "qualification",
        label: "Qualification",
        relatedModel: "Qualification",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "trainer",
        label: "Trainer",
        relatedModel: "Trainer",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "instructor",
        label: "Instructor",
        relatedModel: "Trainer",
        filters: Trainer.instructorFilters,
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    {
      type: DateField,
      opts: { property: "certified_at" },
    },
    {
      type: RelationshipField,
      opts: { property: "qualification", isPrimaryLabelField: 1 },
    },
    {
      type: RelationshipField,
      opts: { property: "trainer" },
    },
    {
      type: RelationshipField,
      opts: { property: "instructor", filters: Trainer.instructorFilters },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      modalOptions: {
        readmodeDefault: false,
      },
      columnFields: ["qualification", "certified_at", "trainer", "instructor"],
      filterFields: [],
      groupBy: "qualification.method_type",
      defaultSort: [
        {
          field: "certified_at",
          order: "desc",
        },
      ],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "default",
      fieldSets: [
        {
          id: "name",
          fields: ["qualification", "certified_at", "instructor"],
        },
      ],
    },
  ];
}
