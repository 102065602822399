import { CrudFieldType, ICrudField } from "../plugins/Cruxtify/CrudField";
import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  StringField,
  StructuredFieldGroupField,
} from "../plugins/Cruxtify/field-types";
import { IStructuredFieldGroupField } from "../plugins/Cruxtify/field-types/StructuredFieldGroupField";

export enum RequestFieldsEnum {
  CURRENT,
  REQUESTED,
}

export interface IRequestField extends IStructuredFieldGroupField {
  type: CrudFieldType;
  property: string;
}

export class RequestField extends StructuredFieldGroupField {
  public static viewComponentName: string = "request-field-view";
  public static formComponentName: string = "request-field";

  public static structuredSubFieldDefs = [
    {
      subFieldId: RequestFieldsEnum.CURRENT,
      type: StringField,
      opts: {} as ICrudField,
    },
    {
      subFieldId: RequestFieldsEnum.REQUESTED,
      type: StringField,
      opts: {
        label: "Requested Change",
      } as ICrudField,
    },
  ];

  constructor(opts: IRequestField, model: CrudModel) {
    // if type is not set, throw an error
    if (typeof opts.type === "undefined") {
      console.error(opts);
      throw new Error("RequestField must have a type defined.");
    }

    opts = {
      ...opts,
      subFieldTypes: {
        [RequestFieldsEnum.CURRENT]: opts.type,
        [RequestFieldsEnum.REQUESTED]: opts.type,
      },
      subFieldOpts: {
        [RequestFieldsEnum.CURRENT]: {
          property: opts.property,
          id: opts.property,
          userPermissions: new UserPermissions(
            {
              [UserRole.SuperAdmin]: UserPermission.Edit,
            },
            UserPermission.Read
          ),
        },
        [RequestFieldsEnum.REQUESTED]: {
          property: opts.property + "_req",
          id: opts.property + "_req",
        },
      },
    };

    super(opts, model);
  }

  public static afterLabelComponents = ["request-field-attention"];
}
