import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  NumberProperty,
  RelationshipProperty
} from "../plugins/Cruxtify/property-types";

import {
  NumberField,
  RelationshipField
} from "../plugins/Cruxtify/field-types";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class StudioEquipment extends CrudModel {
  protected static _typeLabel = "Studio Equipment";
  protected static asProperty = "studio_equipment";
  public static api = {
    path: "studio-equipment"
  };

  protected userIdField = ["studio.users"];
  protected static routeBase = "studio-equipment";

  protected labelGlue: string = " ";

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: NumberProperty,
      opts: { name: "count", label: "Count", default: 1 }
    },

    // relationships

    {
      type: RelationshipProperty,
      opts: {
        name: "equipment",
        label: "Equipment",
        relatedModel: "Equipment",
        rules: ["required"]
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "studio",
        label: "Studio",
        relatedModel: "Studio",
        rules: ["required"]
      }
    }
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    {
      type: NumberField,
      opts: { property: "count" }
    },
    {
      type: RelationshipField,
      opts: { property: "equipment", isPrimaryLabelField: 1 }
    },
    {
      type: RelationshipField,
      opts: { property: "studio" }
    }
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      modalOptions: {
        readmodeDefault: false
      },
      useModal: true,
      columnFields: ["studio", "count", "equipment"],
      filterFields: []
    }
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "default",
      fieldSets: [
        {
          id: "name",
          fields: ["count", "equipment"]
        }
      ]
    }
  ];
}
