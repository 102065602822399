import { SelectField } from "../plugins/Cruxtify/field-types";
import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import { ISelectField } from "../plugins/Cruxtify/field-types/SelectField";

export class GyroMethodField extends SelectField {
  constructor(opts: ISelectField, model: CrudModel) {
    super(opts, model);

    this._options = {
      gt: "Gyrotonic",
      gk: "Gyrokinesis"
    };
  }
}
