import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateProperty,
  RelationshipProperty,
} from "../plugins/Cruxtify/property-types";

import {
  RelationshipField,
  StringField,
  DateField,
} from "../plugins/Cruxtify/field-types";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class CourseAccessibleResource extends CrudModel {
  protected static _typeLabel = "Course Accessible Resource";
  protected static asProperty = "course_accessible_resource";
  public static api = {
    path: "course-accessible-resources",
    fullCache: true,
  };

  protected userIdField = [];
  protected static routeBase = "course-accessible-resources";

  protected labelGlue: string = " ";

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: DateProperty,
      opts: { name: "earliest_date", label: "Earliest Date" },
    },

    // relationships

    {
      type: RelationshipProperty,
      opts: {
        name: "course",
        label: "Course",
        relatedModel: "Course",
        rules: ["required"],
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "resource",
        label: "Resource",
        relatedModel: "Resource",
        rules: ["required"],
      },
    },
  ];

  protected static fieldDefinitions = [
    idAndTimestamps.fields[0],
    idAndTimestamps.fields[1],
    idAndTimestamps.fields[2],

    {
      type: DateField,
      opts: { property: "earliest_date" },
    },
    {
      type: RelationshipField,
      opts: { property: "course", isPrimaryLabelField: 1 },
    },
    {
      type: RelationshipField,
      opts: { property: "resource" },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      inlineEdit: false,
      columnFields: ["course", "earliest_date"],
      filterFields: [],
      searchLabel: "Search",
    },
  ];
}
