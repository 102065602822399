import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import {
  RelationshipField,
  SelectField,
} from "../plugins/Cruxtify/field-types";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class CountryAccessibleResource extends CrudModel {
  protected static _typeLabel = "Country Accessible Resource";
  protected static asProperty = "country_accessible_resource";
  public static api = {
    path: "country-accessible-resources",
  };

  protected userIdField = [];
  protected static routeBase = "country-accessible-resources";

  protected labelGlue: string = " ";

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: StringProperty,
      opts: { name: "compare_type", label: "Access Type" },
    },

    // relationships

    {
      type: RelationshipProperty,
      opts: {
        name: "country",
        label: "Country",
        relatedModel: "Country",
        rules: ["required"],
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "resource",
        label: "Resource",
        relatedModel: "Resource",
        rules: ["required"],
      },
    },
  ];

  protected static fieldDefinitions = [
    idAndTimestamps.fields[0],
    idAndTimestamps.fields[1],
    idAndTimestamps.fields[2],

    {
      type: SelectField,
      opts: {
        property: "compare_type",
        isPrimaryLabelField: 1,
        options: {
          required: "Required",
          blocked: "Blocked",
        },
      },
    },
    {
      type: RelationshipField,
      opts: { property: "country", isPrimaryLabelField: 2 },
    },
    {
      type: RelationshipField,
      opts: { property: "resource" },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: false,
      useModal: true,
      columnFields: ["country", "compare_type"],
      filterFields: [],
    },
  ];
}
