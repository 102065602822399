import * as Sentry from "@sentry/vue";

export default function ({ $auth }) {
  if (!$auth.loggedIn) {
    return;
  }

  const email = $auth.user.email;
  if (!email) {
    return;
  }

  Sentry.setUser({ email });
}
