import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
} from "../plugins/Cruxtify/UserPermission";
import {
  EmailField,
  SelectField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  EmailProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class MailerEvent extends CrudModel {
  protected static _typeLabel = "Mailer Event";
  protected static asProperty = "mailer_event";
  public static api = {
    path: "mailer-events",
  };

  protected userIdField = [];
  protected static routeBase = "mailer-events";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(UserPermission.Read);

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    { type: StringProperty, opts: { name: "subject" } },
    { type: StringProperty, opts: { name: "status" } },
    { type: EmailProperty, opts: { name: "email" } },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    {
      type: StringField,
      opts: { property: "subject", isPrimaryLabelField: 1 },
    },
    {
      type: SelectField,
      opts: {
        property: "status",
        options: {
          hard_bounce: "Hard Bounce",
          soft_bounce: "Soft Bounce",
          reject: "Rejected",
          spam: "Marked as Spam",
        },
      },
    },
    { type: EmailField, opts: { property: "email", isPrimaryLabelField: 2 } },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: false,
      useModal: true,
      columnFields: [],
      enableDynamicFilters: true,
      collectionActionComponents: ["collection-export"],
      enableSearch: true,
      searchLabel: "Search by Subject or Email",
      defaultSort: [
        // {
        //   field: "series",
        //   order: "asc"
        // },
        // {
        //   field: "step_num",
        //   order: "asc"
        // }
      ],
    },
  ];
}
