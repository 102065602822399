import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { BooleanField } from "../plugins/Cruxtify/field-types/BooleanField";
import { RelationshipFieldMany } from "../plugins/Cruxtify/field-types/RelationshipFieldMany";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  BooleanProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class Country extends CrudModel {
  protected static _typeLabel = "Country";
  protected static asProperty = "country";
  public static api = {
    path: "countries",
    fullCache: true,
  };

  protected userIdField = ["id"];
  protected static routeBase = "countries";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  public static EU_COUNTRIES = [
    14, // Austria
    21, // Belgium
    35, // Bulgaria
    56, // Croatia
    58, // Republic of Cyprus
    59, // Czech Republic
    61, // Denmark
    72, // Estonia
    77, // Finland
    78, // France
    86, // Germany
    89, // Greece
    103, // Hungary
    109, // Ireland
    112, // Italy
    124, // Latvia
    130, // Lithuania
    131, // Luxembourg
    139, // Malta
    160, // Netherlands
    187, // Poland
    188, // Portugal
    191, // Romania
    211, // Slovakia
    212, // Slovenia
    218, // Spain
    224, // Sweden
  ];

  public static EU_ID = 265;

  public static isEu(countryId: number): boolean {
    return Country.EU_COUNTRIES.includes(countryId);
  }

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    { type: StringProperty, opts: { name: "name", label: "English Name" } },
    {
      type: StringProperty,
      opts: { name: "official_name", label: "Official Name" },
    },
    {
      type: StringProperty,
      opts: { name: "native_name", label: "Native Name" },
    },
    { type: StringProperty, opts: { name: "alias1", label: "Alias 1" } },
    { type: StringProperty, opts: { name: "alias2", label: "Alias 2" } },
    { type: StringProperty, opts: { name: "alias3", label: "Alias 3" } },
    { type: StringProperty, opts: { name: "iso_code", label: "Iso Code" } },
    { type: StringProperty, opts: { name: "iso_code_3", label: "Iso Code 3" } },
    { type: StringProperty, opts: { name: "emoji", label: "Flag Emoji" } },
    {
      type: BooleanProperty,
      opts: { name: "is_virtual", label: "Is Virtual" },
    },
    {
      type: StringProperty,
      opts: { name: "states_label", label: "States Label" },
    },

    // relationships

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "resources",
        label: "Resource Visibility",
        relatedModel: "CountryAccessibleResource",
        foreignProperty: "country",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "trainers",
        label: "Trainers",
        relatedModel: "Trainer",
        foreignProperty: "country",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "studios",
        label: "Studios",
        relatedModel: "Studio",
        foreignProperty: "country",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "public_classes",
        label: "Public Classes",
        relatedModel: "PublicClass",
        foreignProperty: "country",
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    { type: StringField, opts: { property: "name", isPrimaryLabelField: 1 } },
    { type: StringField, opts: { property: "official_name" } },
    { type: StringField, opts: { property: "native_name" } },
    { type: StringField, opts: { property: "alias1" } },
    { type: StringField, opts: { property: "alias2" } },
    { type: StringField, opts: { property: "alias3" } },
    { type: StringField, opts: { property: "iso_code" } },
    { type: StringField, opts: { property: "iso_code_3" } },
    { type: StringField, opts: { property: "emoji" } },
    {
      type: BooleanField,
      opts: {
        property: "is_virtual",
        description:
          'Is this a real country or a non-political region? E.g. "European Community"',
      },
    },
    {
      type: StringField,
      opts: {
        property: "states_label",
        description:
          "This is what the top-level administrative divisions in the country are referred to as collectively.",
      },
    },

    // relationships
    {
      type: RelationshipFieldMany,
      opts: {
        property: "resources",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "trainers",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "studios",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "public_classes",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: false,
      columnFields: ["emoji", "name", "native_name", "iso_code"],
      filterFields: [],
      collectionActionComponents: ["collection-export"],
      searchLabel: "Search by English Name",
      enableSearch: true,
      defaultSort: [
        // {
        //   field: "series",
        //   order: "asc"
        // },
        // {
        //   field: "step_num",
        //   order: "asc"
        // }
      ],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      type: "TabbedModelLayout",
      opts: {
        id: "Crudform",
        tabs: [
          {
            id: "overviewTab",
            label: "Overview",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                id: "default",
                fieldSets: [
                  {
                    id: "name",
                    fields: [
                      "name",
                      "official_name",
                      "native_name",
                      "alias1",
                      "alias2",
                      "alias3",
                      "iso_code",
                      "iso_code_3",
                      "emoji",
                      "is_virtual",
                      "states_label",
                    ],
                  },
                ],
              },
            },
          },
          {
            id: "trainersTab",
            label: "Trainers",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "trainers",
                    fields: ["trainers"],
                  },
                ],
              },
            },
          },
          {
            id: "studiosTab",
            label: "Studios",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "studios",
                    fields: ["studios"],
                  },
                ],
              },
            },
          },
          {
            id: "classesTab",
            label: "Virtual Classes",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "classes",
                    fields: ["public_classes"],
                  },
                ],
              },
            },
          },
          {
            id: "otherTab",
            label: "Other",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "other",
                    fields: ["resources"],
                  },
                ],
              },
            },
          },
        ],
      },
    },
  ];
}
