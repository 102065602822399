import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { BooleanField } from "../plugins/Cruxtify/field-types/BooleanField";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  BooleanProperty,
  DateProperty,
  DateTimeProperty,
  JsonProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
  UrlProperty,
} from "../plugins/Cruxtify/property-types";

import { GyroMethodField } from "../fields/GyroMethodField";
import {
  CheckboxField,
  DateField,
  DateTimeField,
  RelationshipField,
  SelectField,
  WysiwygField,
} from "../plugins/Cruxtify/field-types";

import { Studio } from "./Studio";

import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

import { extendWithDeleted } from "./collection-layouts/extend-with-deleted";

export class PublicClass extends CrudModel {
  protected static _typeLabel = "Virtual Class";
  protected static asProperty = "public_class";
  public static api = {
    path: "public-classes",
  };

  protected userIdField = ["trainer.user"];
  protected static routeBase = "virtual-classes";

  protected labelGlue: string = " ";

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "legacy_ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "name",
        label: "Name",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: { name: "description", label: "Description", rules: ["required"] },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "start_date",
        label: "Date & Time (in your current timezone)",
        description:
          "Please enter the date and time relative to your current device's timezone.\r\n\r\nFor example, if your computer/phone is set to the New York timezone right now but the class will be listed in Chicago time (1 hour earlier), please enter the time it will be in New York when the class starts.\r\n\r\nSo if the class starts at 10:00 in Chicago, you would enter 11:00 since that's the time it would be in New York.",
        rules: ["required"],
        sortable: true,
      },
    },
    {
      type: StringProperty,
      opts: { name: "timezone", label: "Timezone" },
    },
    {
      type: NumberProperty,
      opts: { name: "length", label: "Length", rules: ["required"] },
    },
    {
      type: UrlProperty,
      opts: {
        name: "link",
        label: "Registration & More Information Link or Email Address",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: { name: "method_type", label: "Method Type", rules: ["required"] },
    },
    {
      type: BooleanProperty,
      opts: { name: "repeats", label: "Repeats" },
    },
    {
      type: StringProperty,
      opts: { name: "repeatsInterval", label: "Repeating Interval" },
    },
    {
      type: JsonProperty,
      opts: {
        name: "repeatsDay",
        label: "Repeats On Day(s)",
      },
    },
    {
      type: DateProperty,
      opts: { name: "repeatsUntil", label: "Repeats Until" },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "repeatsEndCalc",
        label: "RepeatsEndCalc",
        computed: true,
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: NumberProperty,
      opts: { name: "difficulty", label: "Difficulty", rules: ["required"] },
    },

    // relationships
    {
      type: RelationshipProperty,
      opts: {
        name: "trainer",
        label: "Trainer",
        relatedModel: "Trainer",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "studio",
        label: "Studio",
        relatedModel: "Studio",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "country",
        label: "Country",
        relatedModel: "Country",
        rules: ["required"],
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "language",
        label: "Language",
        relatedModel: "Language",
        rules: ["required"],
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Event Owner",
        relatedModel: "User",
        isRemotelyComputed: true,
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    {
      type: StringField,
      opts: { property: "name", isPrimaryLabelField: 1, rules: ["required"] },
    },
    {
      type: WysiwygField,
      opts: { property: "description", rules: ["required"] },
    },
    {
      type: DateTimeField,
      opts: { property: "start_date", rules: ["required"] },
    },
    {
      type: SelectField,
      opts: {
        property: "length",
        rules: ["required"],
        options: [30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180].reduce(
          (mapped, length) => {
            mapped[length] = length + " minutes";
            return mapped;
          },
          {}
        ),
      },
    },
    {
      type: StringField,
      opts: { property: "link", rules: ["required"] },
    },
    {
      type: GyroMethodField,
      opts: { property: "method_type", rules: ["required"] },
    },
    {
      type: BooleanField,
      opts: { property: "repeats" },
    },
    {
      type: CheckboxField,
      opts: {
        property: "repeatsDay",
        multiple: true,
        options: {
          "0": "Sunday",
          "1": "Monday",
          "2": "Tuesday",
          "3": "Wednesday",
          "4": "Thursday",
          "5": "Friday",
          "6": "Saturday",
        },
        conditional: { property: "repeats" },
        rules: ["required"],
      },
    },
    {
      type: DateField,
      opts: { property: "repeatsUntil", conditional: { property: "repeats" } },
    },
    {
      type: SelectField,
      opts: {
        property: "difficulty",
        options: {
          0: "Beginner",
          1: "Intermediate",
          2: "Advanced",
          3: "Any Level",
        },
        rules: ["required"],
      },
    },

    // relationships
    {
      type: RelationshipField,
      opts: {
        property: "trainer",
        rules: ["required"],
        default(prop) {
          return prop.$nuxt.$auth.user.trainer_ids
            ? prop.$nuxt.$auth.user.trainer_ids[0]
            : undefined;
        },
      },
    },
    {
      type: RelationshipField,
      opts: { property: "studio", filters: Studio.publicVisibilityFilters },
    },
    {
      type: RelationshipField,
      opts: {
        property: "user",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: RelationshipField,
      opts: { property: "country", rules: ["required"] },
    },
    {
      type: RelationshipField,
      opts: { property: "language", rules: ["required"] },
    },
  ];

  protected static collectionLayoutDefinitions = extendWithDeleted([
    {
      id: "table",
      inlineEdit: false,
      useModal: true,
      columnFields: [
        "name",
        "trainer",
        "studio",
        "start_date",
        "repeats",
        "difficulty",
        "language",
      ],
      // filterFields: ["language", "start_date", "method_type"],
      enableDynamicFilters: true,
      collectionActionComponents: ["collection-export"],
      searchLabel: "Search by name",
      enableSearch: true,
      headerComponents: ["collection-alerts"],
      defaultSort: [
        {
          field: "start_date",
          order: "desc",
        },
        // {
        //   field: "step_num",
        //   order: "asc"
        // }
      ],
    },
  ]);

  protected static modelLayoutDefinitions = [
    {
      id: "default",
      fieldSets: [
        {
          id: "name",
          fields: ["name", "method_type", "difficulty"],
        },
        {
          id: "datetime",
          fields: [
            "start_date",
            "length",
            "repeats",
            "repeatsDay",
            "repeatsUntil",
          ],
        },
        {
          id: "name",
          fields: [
            "trainer",
            "studio",
            "country",
            "language",
            "link",
            "description",
          ],
        },
        {
          id: "meta",
          fields: ["id", "created_at", "updated_at", "user"],
        },
      ],
    },
  ];
}
