import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { DateTimeField, StringField } from "../plugins/Cruxtify/field-types";

export class ContractEvent extends CrudModel {
  protected static _typeLabel = "Contract Event";
  protected static asProperty = "contract_event";

  public static api = {
    path: "contract-events",
  };

  protected userIdField = [];
  protected static routeBase = "contract-events";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        useLocaleString: false,
        userPermissions: UserPermission.Read,
        isRemotelyComputed: true,
        sortable: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        displayRelative: false,
        userPermissions: UserPermission.Read,
        isRemotelyComputed: true,
        sortable: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        displayRelative: true,
        userPermissions: UserPermission.Read,
        isRemotelyComputed: true,
        sortable: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        label: "Deleted At",
        displayRelative: true,
        userPermissions: UserPermission.Read,
        isRemotelyComputed: true,
        sortable: true,
      },
    },

    {
      type: StringProperty,
      opts: { name: "message", label: "Message" },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contract",
        label: "Contract",
        relatedModel: "Contract",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        label: "Date",
      },
    },

    {
      type: StringField,
      opts: { property: "message", isPrimaryLabelField: 1 },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      modalOptions: {
        readmodeDefault: false,
      },
      useModal: true,
      columnFields: [],
      filterFields: [],
    },
  ];
}
