import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { RelationshipFieldMany } from "../plugins/Cruxtify/field-types/RelationshipFieldMany";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  JsonProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import {
  RelationshipField,
  SelectField,
} from "../plugins/Cruxtify/field-types";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class ContractType extends CrudModel {
  protected static _typeLabel = "Contract Type";
  protected static asProperty = "contract_type";
  public static api = {
    path: "contract-types",
    fullCache: true,
  };

  protected static routeBase = "contract-types";

  protected labelGlue: string = " ";

  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },

    { type: StringProperty, opts: { name: "name", label: "Name", cols: 12 } },
    {
      type: StringProperty,
      opts: { name: "method_type", label: "Method Type" },
    },
    {
      type: StringProperty,
      opts: {
        name: "expired_notice_days",
        label: "Days After Expiration to Notify",
        description:
          "Enter the number of days after a contract of this type has expired on which the signer should receive the expiration notice email. Separate multiple by comma.",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "expiring_soon_notice_days",
        label: "Days Before Expiration to Notify",
        description:
          "Enter the number of days before a contract of this type has expired on which the signer should receive the expiration notice email. Separate multiple by comma.",
      },
    },
    {
      type: JsonProperty,
      opts: {
        name: "country_licenses",
        label: "Country License Configuration",
      },
    },

    // relationships
    {
      type: RelationshipProperty,
      opts: {
        name: "mailer_template_expire_soon",
        label: "Expiring Soon Email Template",
        relatedModel: "MailerTemplate",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "final_course",
        label: "Final Course",
        relatedModel: "Course",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "mailer_template_expired",
        label: "Expired Email Template",
        relatedModel: "MailerTemplate",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "contracts",
        label: "Contracts",
        relatedModel: "Contract",
        foreignProperty: "contract_type",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "subsequent_contract_type",
        label: "Subsequent Contract Type",
        relatedModel: "ContractType",
        foreignProperty: "preceding_contract_types",
        description:
          "If the trainer has a contract of this type, they will not be sent any expiration reminders.",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "preceding_contract_types",
        label: "Preceding Contract Types",
        relatedModel: "ContractType",
        foreignProperty: "subsequent_contract_type",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "contract_type_country",
        description:
          "The countries in which this contract type is used. If it's an offline contract, leave the API ID blank.",
        label: "Countries",
        relatedModel: "ContractTypeCountry",
        foreignProperty: "contract_type",
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    { type: StringField, opts: { property: "name", isPrimaryLabelField: 1 } },

    { type: StringField, opts: { property: "expiring_soon_notice_days" } },
    { type: StringField, opts: { property: "expired_notice_days" } },
    {
      type: SelectField,
      opts: {
        property: "method_type",
        options: {
          gyrotonic: "Gyrotonic",
          gyrokinesis: "Gyrokinesis",
        },
      },
    },

    // relationships
    { type: RelationshipField, opts: { property: "final_course" } },
    {
      type: RelationshipField,
      opts: { property: "mailer_template_expire_soon" },
    },
    { type: RelationshipField, opts: { property: "mailer_template_expired" } },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "contracts",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "contract_type_country",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "subsequent_contract_type",
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      type: "TabbedModelLayout",
      opts: {
        id: "tabs",
        tabs: [
          {
            id: "overviewTab",
            label: "Overview",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "name",
                    // title: "Name",
                    fields: ["name", "method_type", "final_course"],
                  },
                  {
                    id: "contact_info",
                    // title: "Contact Information",
                    fields: [
                      "subsequent_contract_type",
                      "expiring_soon_notice_days",
                      "expired_notice_days",
                      "mailer_template_expire_soon",
                      "mailer_template_expired",
                    ],
                  },
                ],
              },
            },
          },
          {
            id: "countries",
            label: "Countries",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    // title: "Name",
                    fields: ["contract_type_country"],
                  },
                ],
              },
            },
          },
          {
            id: "contracts",
            label: "Contracts",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "contracts",
                    // title: "Name",
                    fields: ["contracts"],
                  },
                ],
              },
            },
          },
        ],
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: false,
      columnFields: ["name", "method_type", "final_course"],
      collectionActionComponents: ["collection-export"],
      filterFields: [],
      searchLabel: "Search",
      defaultSort: [
        {
          field: "name",
          order: "asc",
        },
        // {
        //   field: "step_num",
        //   order: "asc"
        // }
      ],
    },
  ];
}
