import { DateTimeField, NumberField } from "../../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
} from "../../plugins/Cruxtify/property-types";
import { UserPermission } from "../../plugins/Cruxtify/UserPermission";

export const idAndTimestamps = {
  properties: [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        useLocaleString: false,
        userPermissions: UserPermission.Read,
        isRemotelyComputed: true,
        sortable: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        displayRelative: true,
        userPermissions: UserPermission.Read,
        isRemotelyComputed: true,
        sortable: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        displayRelative: true,
        userPermissions: UserPermission.Read,
        isRemotelyComputed: true,
        sortable: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        label: "Deleted At",
        displayRelative: true,
        userPermissions: UserPermission.Read,
        isRemotelyComputed: true,
        sortable: true,
      },
    },
  ],

  fields: [
    {
      type: NumberField,
      opts: {
        property: "id",
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "deleted_at",
      },
    },
  ],
};
