import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateProperty,
  RelationshipProperty,
} from "../plugins/Cruxtify/property-types";

import {
  DateField,
  RelationshipField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";
import { Trainer } from "./Trainer";

export class CompletedCourse extends CrudModel {
  protected static _typeLabel = "Completed Course";
  protected static asProperty = "completed_course";
  public static api = {
    path: "completed-courses",
  };

  protected userIdField = [];
  protected static routeBase = "completed-courses";

  protected labelGlue: string = " ";

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: DateProperty,
      opts: { name: "course_completed_at", label: "Date" },
    },

    // relationships

    {
      type: RelationshipProperty,
      opts: {
        name: "course",
        label: "Course",
        relatedModel: "Course",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "trainer",
        label: "Trainer",
        relatedModel: "Trainer",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "instructor",
        label: "Instructor",
        relatedModel: "Trainer",
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    {
      type: DateField,
      opts: { property: "course_completed_at" },
    },
    {
      type: RelationshipField,
      opts: { property: "course", isPrimaryLabelField: 1 },
    },
    {
      type: RelationshipField,
      opts: { property: "trainer" },
    },
    {
      type: RelationshipField,
      opts: {
        property: "instructor",
        filters: Trainer.instructorFilters,
      },
    },

    {
      type: StringField,
      opts: {
        id: "attestation",
        label: "Attestation",
        property: "course_completed_at",
        viewComponentName: "download-attestation",
        formComponentName: "download-attestation",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      modalOptions: {
        readmodeDefault: false,
      },
      columnFields: [
        "course",
        "course_completed_at",
        "trainer",
        "instructor",
        "attestation",
      ],
      filterFields: [],
      groupBy: "course.method_type",
      defaultSort: [
        {
          field: "course_completed_at",
          order: "desc",
        },
      ],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "default",
      fieldSets: [
        {
          id: "name",
          fields: [
            "course",
            "course_completed_at",
            "instructor",
            "attestation",
          ],
        },
      ],
    },
  ];

  public get attestationDownloadUrl() {
    return this.$nuxt.$api.buildUrl(`${this.api.path}/attestation/${this.id}`);
  }
}
